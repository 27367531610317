body {
    background-color: white;
}

.logo-wrapper{
    background: url(../img/logo.png) left top no-repeat;
    display: block;
    width: 350px;
    height: 125px;
    position: absolute;
    cursor: pointer;
    background-size: 100%;
    background-position: 0 10px;
}
.top-bar{
    background: rgba(0,0,0,.8);
    height: 70px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 24px;
    z-index: 1;
}
.top-bar .container{
    position: relative;
}
.credentials{
    position: absolute;
    right: 0px;
    top: 34px;
}
.credentials a{
    color: white;
}
.login-register{
    float: left;
}
.language-switch img{
    float: left;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 5px;
}
.hero-sec{
    
}
.hero-sec .heroImage{
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    top: 0px;
}
.search-block{
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: 2;
    top: 200px;
    min-height: 300px;
}
.search-block .nav-tabs li a{
    border: solid 1px rgb(255, 255, 255);
  background-color: rgba(53, 53, 54,.8);
    width: 100%;
    text-align: center;
    display: block;
    min-height: 50px;
    line-height: 50px;
    color: #ffffff;
    font-weight: bold;
}
.search-block .nav-tabs li{
    padding-left: 0px;
    padding-right: 0px;
}
.search-block .nav-tabs li:first-child a{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.search-block .nav-tabs li:last-child a{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.search-block .nav-tabs{
    border: none;
}
.search-block .nav-tabs li a.active{
    background-color: rgb(252, 182, 20);
    color: #ffffff;
    border: solid 1px rgb(255, 255, 255);
    text-decoration: none;
}
.live-updates{
    background: rgba(255,255,255,.6)
}
.live-update-btn{
    background: blue;
}
.search-block .tab-pane{
    background: rgba(255,255,255,.5);
}